import { observable, action, decorate } from 'mobx'
import LocalGridConfig from '../../config/LocalGridConfig';
import Axios from 'axios'

export default class AppoinmentStore {
  list_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
  agGrid = null
  dropdown_appointment_with_list = null;


  setupGrid = (params) => {
    this.agGrid = params
  }

  onGridChanged = (params) => {
    localStorage.setItem('appoinment_token', JSON.stringify(params.columnApi.getColumnState()))
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  getappoinmentlist = () => {
    return Axios.post('/api/appointment/list').then(({ data }) => {
      data.data.forEach((item) => {
        item.added_by = item.added_by?.first_name + " " + item.added_by?.last_name
        item.outcome_by = item.outcome_by ? item.outcome_by?.first_name + " " + item.outcome_by?.last_name : null
        item.appointment_type_list = item.appointment_type === 0 ? "Service User" : "House";
      })
      this.list_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })

  }

  addAppoinment = (data) => {
    return Axios.post(`/api/appointment/add`, data).then(({ data }) => {
      this.getappoinmentlist()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  editAppoinment = (data) => {
    return Axios.post(`/api/appointment/edit/${data.id}`, data).then(() => {
      this.getappoinmentlist()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getAppointmentWithList = (type) => {
    return Axios.post(`api/appointment/dropdownwithat`, type)
      .then(({ data }) => {
        this.dropdown_appointment_with_list = data.data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }
}

decorate(AppoinmentStore, {
  list_data: observable,
  total: observable,
  per_page: observable,
  current_page: observable,
  dropdown_appointment_with_list: observable,
  getappoinmentlist: action,
  setupGrid: action,
  setPageSize: action,
  addAppoinment: action,
  onGridChanged: action,
  editAppoinment: action,
  getAppointmentWithList: action,
  agGrid: observable

})