import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerSideGridConfig from "../../config/GridConfig";
import { vsmNotify } from "../../config/messages";
// import { CONSTANT } from "../../config/Constant";

export default class CustomReportStore {
  agGrid = null;
  total = 1;
  per_page = ServerSideGridConfig.options.paginationPageSize;
  loadingList = false;
  current_page = 1;
  showList = false;
  payloadList = {
    type: null,
    sub_type: null,
    from_date: null,
    to_date: null
  };
  category_list = [];

  getList = async (payload) => {
    return Axios.post(`/api/reports/custom-reports`, payload)
      .then(({ data }) => {
        this.total = data.total;
        this.current_page = data.current_page;
        // this.setShowList(true);
        return data;
      }).catch(({ response: { data } }) => {
        var errors = []
        var notify = null
        this.setShowList(false);
        if (data.errors) {
          Object.keys(data.errors).forEach(name => {
            if (name === 'both') {
              notify = data.errors[name][0]
            } else {
              errors.push({ name, errors: data.errors[name] })
            }
          })
        }
        return Promise.reject({ errors, notify })
      }).finally(() => {
        this.setloadingList(false);
      });
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    if (params) {
      this.agGrid = params;
      const { api } = params;
      let datasource = this.createDatasource(ServerSideGridConfig.options);
      api.setServerSideDatasource(datasource);
    }
  };

  // To export listing data as csv or pdf file
  doExportDownloadDetail = async (payload) => {
    return Axios.post("/api/reports/export-custom-reports", payload)
      .then(({ data }) => {
        if (data.export_url) {
          const link = document.createElement("a");
          link.href =
            process.env.REACT_APP_BASE_URL +
            data.export_url.replace("/var/www", "/api");
          // if (payload.export_type === CONSTANT.ExportTypeListing.PDF) {
          //   link.target = "_blank"
          // }
          let parts = data.export_url.split("/");
          let filename = parts[parts.length - 1];
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        vsmNotify.success({ message: data.message });
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          vsmNotify.error({ message: data.errors[name][0] });
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setPayloadList = (data) => {
    this.payloadList = data
  }

  setloadingList = (data) => {
    this.loadingList = data
  }

  setShowList = (data = false) => {
    return new Promise((resolve) => {
      this.showList = data
      if (this.agGrid) {
        this.setupGrid(this.agGrid)
      }
      resolve()

    })
  }

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          // filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
          type: this.payloadList.type,
          sub_type: this.payloadList.sub_type,
          from_date: this.payloadList.from_date,
          to_date: this.payloadList.to_date,
          export: false,
        };
        this.getList(payload).then((data) => {
          if (data.total === 0) {

            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          if (this.agGrid && this.agGrid.columnApi && data.total) {
            this.agGrid.columnApi.getAllColumns().forEach(function (column) {
              allColumnIds.push(column.col_id);
            });
          }
        })
      },
    };
  };

  // Filter function for no record found message
  onFilterChangedList = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  onGridChanged = (params) => {
    localStorage.setItem(
      "report_custom_report",
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // To set Category List in dropdown
  setCategoryList = (data) => {
    this.category_list = data
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (![document].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ""
        );
      }
    });
    return formData;
  };

  getCategoryList = (data) => {
    return Axios.post(`/api/user/edit/note/getcategorylist`, data).then(({ data }) => {
      if (data?.subcategory) {
        this.setCategoryList(data.subcategory);
      }
      return data
    })
  }
}

decorate(CustomReportStore, {
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  category_list: observable,
  payloadList: observable,
  loadingList: observable,
  showList: observable,
  setShowList: action,
  getList: action,
  setPageSize: action,
  setPayloadList: action,
  setloadingList: action,
  setupGrid: action,
  getCategoryList: action,
  setCategoryList: action,
  createDatasource: action,
  onGridChanged: action,
  JsonToFormData: action,
  onFilterChanged: action,
  exportCsv: action,
  doExportDownloadDetail: action,
});
