import React from 'react';
const AuditLog = React.lazy(() => import('../pages/AuditLog/AuditLog'))
const Login = React.lazy(() => import('../pages/authpage/Login'));
const ForgetPassword = React.lazy(() => import('../pages/authpage/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/authpage/ResetPassword'));
const DashboardIndex = React.lazy(() => import('../pages/Dashboard/DashBoardIndex'));
const AreaLocality = React.lazy(() => import('../pages/Master/AreaLocality/AreaLocality'));
const NationalityMaster = React.lazy(() => import('../pages/Master/NationalityManagement/NationalityMaster'));
const PageNotFound = React.lazy(() => import('../pages/CommonPages/PageNotFound'));
const EthnicityMaster = React.lazy(() => import('../pages/Master/ethnicity/EthnicityMaster'));
const Rolemanagement = React.lazy(() => import('../pages/RoleManagement/Rolemanagement'));
const ReligionMaster = React.lazy(() => import('../pages/Master/ReligionManagement/ReligionMaster'));
const CatogariesMaster = React.lazy(() => import('../pages/Master/Catogaries/CatogariesMaster'));
const SubCatogariesMaster = React.lazy(() => import('../pages/Master/Subcategory/SubCatogariesMaster'));
const ManageTraining = React.lazy(() => import('../pages/Master/Training/MasterTraining'));
const MyProfile = React.lazy(() => import('../pages/MyProfile'));
const ChangePassword = React.lazy(() => import('../pages/MyProfile/ChangePassword'));
const UserManagement = React.lazy(() => import('../pages/UserManagement'));
const AddAndEditUser = React.lazy(() => import('../pages/UserManagement/component/AddAndEditUser'));
const LeaveTracker = React.lazy(() => import('../pages/UserManagement/tabs/LeaveTracker'));
const Contacts = React.lazy(() => import('../pages/Contacts'));
const HrManagement = React.lazy(() => import('../pages/UserManagement/tabs/HrDetails'));
const NotesManagement = React.lazy(() => import('../pages/UserManagement/tabs/Notes'));
const QualificationTraining = React.lazy(() => import('../pages/UserManagement/tabs/Training'));
const PerformanceReview = React.lazy(() => import('../pages/UserManagement/tabs/PerformanceReview'));
const Housing = React.lazy(() => import('../pages/Housing/index.js'));
const HousingAdd = React.lazy(() => import('../pages/Housing/component/AddComponent'));
const HousingEdit = React.lazy(() => import('../pages/Housing/component/EditComponent'));
const HousingView = React.lazy(() => import('../pages/Housing/component/ViewComponent'));
const HousingDocuments = React.lazy(() => import('../pages/Housing/DocumentsListing/DocumentsListing'));
const HousingNotes = React.lazy(() => import('../pages/Housing/NotesListing/NotesListing'));
const Appointments = React.lazy(() => import('../pages/Appointments/Appointments'))
const ServiceUsers = React.lazy(() => import('../pages/ServiceUsers'))
const ServiceUsersAdd = React.lazy(() => import('../pages/ServiceUsers/component/AddComponent'))
const ServiceUsersEdit = React.lazy(() => import('../pages/ServiceUsers/component/EditComponent'))
const ComplainLog = React.lazy(() => import('../pages/Reports/complaintLog/ComplainLog'))
const SafeguardLog = React.lazy(() => import('../pages/Reports/SafeGuardLog/SafeguardLog'))
const DailyLog = React.lazy(() => import('../pages/Reports/DailyLog'))
const NotesLog = React.lazy(() => import('../pages/Reports/NotesLog'))
const SuperVisionLog = React.lazy(() => import('../pages/Reports/SupervisionLog'))
const TrainingMatrix = React.lazy(() => import('../pages/Reports/trainingMatrix/index'))
const BroadcastMessage = React.lazy(() => import('../pages/BroadcastMessage'))
const HandoverSheet = React.lazy(() => import('../pages/Forms/HandoverSheet'))
const RiskAssessment = React.lazy(() => import('../pages/Forms/RiskAssessment'))
const Notification = React.lazy(() => import('../pages/Notification'))
const SystemForms = React.lazy(() => import('../pages/Forms/SystemForms'))
const ViewSystemForms = React.lazy(() => import('../pages/Forms/SystemForms/component/ViewComponent'))
const ExportSystemForms = React.lazy(() => import('../pages/Forms/SystemForms/component/ExportComponent'))
const RiskAssessmentView = React.lazy(() => import('../pages/Forms/RiskAssessment/component/ViewComponent'))
const Settings = React.lazy(() => import('../pages/Forms/Settings'))
const Audit = React.lazy(() => import('../pages/Audit'))
const MedicationErrorReport = React.lazy(() => import('../pages/Forms/MedicationErrorReport'))
const IncidentReport = React.lazy(() => import('../pages/Forms/IncidentReport'))
const AuditForms = React.lazy(() => import('../pages/AuditForms'))
const CustomReport = React.lazy(() => import('../pages/Reports/CustomReports/index'))

export const Apps_RouterConfig = [
	{
		title: 'Coreprospect Login',
		path: '/',
		component: Login,
		default: true
	},
	{
		title: 'Forgot Password',
		path: '/forget-password',
		component: ForgetPassword,
		default: true
	},
	{
		title: 'Reset Password',
		path: '/reset/:key',
		component: ResetPassword,
		default: true
	},
	{
		title: 'Dashboard',
		path: '/dashboard',
		component: DashboardIndex,
		authdefault: true,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Area Locality',
		path: '/masters/area-locality',
		component: AreaLocality,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Master Area Locality',
		path: '/masters/nationality',
		component: NationalityMaster,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ethnicity Management',
		path: '/masters/ethnicity',
		component: EthnicityMaster,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Category Type',
		path: '/masters/category-type',
		component: CatogariesMaster,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Category',
		path: '/masters/category',
		component: SubCatogariesMaster,
		apps_auth: true,
		setting: { header: true, nav: true }
	},

	{
		title: 'Religion',
		path: '/masters/religion',
		component: ReligionMaster,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Role ',
		path: '/roles-management',
		component: Rolemanagement,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Manage Training',
		path: '/masters/training',
		component: ManageTraining,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Profile Details',
		path: '/profile',
		component: MyProfile,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Change Password',
		path: '/change-password',
		component: ChangePassword,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Staff Management',
		path: '/staff-management/all-staff',
		component: UserManagement,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Staff Details',
		path: '/staff-management/staff-details',
		component: AddAndEditUser,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Staff Details',
		path: '/staff-management/staff-details/:id',
		component: AddAndEditUser,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Staff Details',
		path: '/staff-management/leave-tracker/:id',
		component: LeaveTracker,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Contacts',
		path: '/contacts',
		component: Contacts,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Hr Details',
		path: '/staff-management/hr-details/:id',
		component: HrManagement,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Notes',
		path: '/staff-management/notes/:id',
		component: NotesManagement,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Qualification Training',
		path: '/staff-management/qualifications/training/:id',
		component: QualificationTraining,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Performance Reviews',
		path: '/staff-management/performance-review/:id',
		component: PerformanceReview,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Housing',
		path: '/housing',
		component: Housing,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Add Housing',
		path: '/housing/new',
		component: HousingAdd,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Edit Housing',
		path: '/housing/housing-edit/:id',
		component: HousingEdit,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'View Housing',
		path: '/housing/housing-view/:id',
		component: HousingView,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Housing Documents',
		path: '/housing/documents/:id',
		component: HousingDocuments,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Housing Notes',
		path: '/housing/notes/:id',
		component: HousingNotes,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Appointments',
		path: '/appointments',
		component: Appointments,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Service Users',
		path: '/service-users',
		component: ServiceUsers,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Add Service Users',
		path: '/service-users/new',
		component: ServiceUsersAdd,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Edit Service Users',
		path: '/service-users/:id',
		component: ServiceUsersEdit,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Complaints Log',
		path: '/reports/complaints-log',
		component: ComplainLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Manage Safeguarding Log',
		path: '/reports/safeguarding-log',
		component: SafeguardLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Audit Log',
		path: '/Audit-log',
		component: AuditLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Daily Log',
		path: '/reports/daily-log-reported',
		component: DailyLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Notes Log',
		path: '/reports/staff-notes',
		component: NotesLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Supervision Log',
		path: '/reports/supervision-log',
		component: SuperVisionLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Communication Board',
		path: '/communication-board',
		component: BroadcastMessage,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Supervision Log',
		path: '/reports/supervision-log',
		component: SuperVisionLog,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Training Matrix',
		path: '/reports/training-matrix',
		component: TrainingMatrix,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Handover Sheet',
		path: '/forms/handover-sheet',
		component: HandoverSheet,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Risk Assessment',
		path: '/forms/risk-assessment',
		component: RiskAssessment,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Notification',
		path: '/notification',
		component: Notification,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'System Forms',
		path: '/forms/system-forms',
		component: SystemForms,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'View System Forms',
		path: '/forms/view-system-forms',
		component: ViewSystemForms,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Export System Forms',
		path: '/forms/export-system-forms',
		component: ExportSystemForms,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Risk Assessment View',
		path: '/risk-assessment-view',
		component: RiskAssessmentView,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Settings',
		path: '/forms/setting',
		component: Settings,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Audit',
		path: '/audits',
		component: Audit,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Medication Error Report',
		path: '/reports/medicationerror',
		component: MedicationErrorReport,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Incident Report',
		path: '/reports/incident-report',
		component: IncidentReport,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Audit Forms',
		path: '/audit-forms',
		component: AuditForms,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Custom Report',
		path: '/reports/custom-report',
		component: CustomReport,
		apps_auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Page Not Found',
		path: '/',
		component: PageNotFound,
		errorpage: true,
		setting: { header: true, nav: true }
	},
]