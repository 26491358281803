import { observable, action, decorate } from 'mobx'
import LocalGridConfig from '../../config/LocalGridConfig';
import Axios from 'axios'

export default class SafeguardStore {

  list_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
  agGrid = null;
  dropdown_house_list = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  onFilterChanged = (params) => {
    if (params.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay()
    }
    else {
      this.agGrid.api.hideOverlay()
    }
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('safeguard_token', JSON.stringify(params.columnApi.getColumnState()))
  }


  exportCsv = () => {
    var params = {
      columnKeys: ['id', 'safeguard_date', 'safeguard_made_by', 'detail']
    };
    this.agGrid.api.exportDataAsCsv(params)
  }

  getSafeguardList = () => {
    return Axios.post('/api/safeguard_log/list').then(({ data }) => {
      this.list_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }


  addSafeGuardList = (data) => {
    return Axios.post(`api/safeguard_log/add`, data).then(({ data }) => {
      this.getSafeguardList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  editsafeGuardList = (formData, data) => {
    return Axios.post(`api/safeguard_log/edit/${data.action_id}`, formData).then(({ data }) => {
      this.getSafeguardList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  JsonToFormData = (data) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (!['document'].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  handleReset = () => {
    localStorage.removeItem('safeguard_token')
    this.agGrid.api.setFilterModel(null)
    this.agGrid.api.onFilterChanged(null)
    this.agGrid.columnApi.resetColumnState()
  }

  getHouseList = () => {
    return Axios.get(`api/house/gethouselist`)
      .then(({ data }) => {
        this.dropdown_house_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

}

decorate(SafeguardStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  dropdown_house_list: observable,
  getSafeguardList: action,
  setPageSize: action,
  setupGrid: action,
  exportCsv: action,
  addSafeGuardList: action,
  editsafeGuardList: action,
  onGridChanged: action,
  JsonToFormData: action,
  onFilterChanged: action,
  handleReset: action,
  getHouseList: action
})