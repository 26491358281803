import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import { Messages } from "../../config/messages/messages";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class FinanceTabStore {
  agGrid = null;
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  editValues = null;
  deleteValues = null;
  List_data = null;
  available_balance = null;

  setAvailableBalance = (params) => {
    this.available_balance = params;
  }

  getList = (id) => {
    return Axios.post(`/api/service_user/edit/finance/list/${id}`).then(
      ({ data }) => {
        if(data.data)
        data.data.forEach((item) => {
          if (item.type === 1) {
            item.income = item.amount ?? "00.00"
            item.expenditure = "00.00"
          } else {
            item.expenditure = item.amount ?? "00.00"
            item.income = "00.00"
          }
        });
        if(data.data && data.data.length>0) {
          this.setAvailableBalance(data.data[data.data.length-1]?.balance)
        }
        this.List_data = data.data ?? [];
        this.total = data.total;
        // this.setPageSize(data.per_page);
        this.current_page = data.current_page;
        return data;
      }
    ).catch((error) => {
      console.log("error",error)
      vsmNotify.error({ message: Messages.Something_Went_Wrong });
      // history.push('/housing')
    });
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // refresh the grid
  refreshList = () => {
    if (this.agGrid) {
      this.agGrid.api.setFilterModel(null);
      this.agGrid.columnApi.resetColumnState();
      this.agGrid.api.onFilterChanged(null);
      localStorage.removeItem("service_user_finance_tab");
    }
  }

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Filter function for no record found message
  onFilterChangedList = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (![document].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ""
        );
      }
    });
    return formData;
  };

  AddFinance = (data, parentId) => {
    return Axios.post(`/api/service_user/edit/finance/add/${parentId}`, data)
      .then(({ data }) => {
        this.getList(parentId)
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  EditFinance = (data, parentId, childId) => {
    return Axios.post(`/api/service_user/edit/finance/edit/${parentId}/${childId}`, data)
      .then(({ data }) => {
        this.getList(parentId)
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  DeleteFinance = (data, parentId, childId) => {
    return Axios.post(`/api/service_user/edit/finance/delete/${childId}`, data)
      .then(({ data }) => {
        this.getList(parentId)
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  ReadFinance = (data, childId) => {
    return Axios.post(`/api/service_user/edit/finance/view/${childId}`, data)
      .then(({ data }) => {
        this.setEditValues = data;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  onGridChanged = (params) => {
    localStorage.setItem(
      "service_user_finance_tab",
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
}

decorate(FinanceTabStore, {
  agGrid: observable,
  editValues: observable,
  deleteValues: observable,
  total: observable,
  per_page: observable,
  List_data: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  AddFinance: action,
  EditFinance: action,
  DeleteFinance: action,
  JsonToFormData: action,
  setEditValues: action,
  setDeleteValues: action,
  ExportFinance: action,
  ReadFinance: action
});
