import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class BroadcastMessageStore {

  dropdown_message_house_list = null
  dropdown_broadcast_message_list = null
  broadcastmessage_data = null
  houseId = null;
  dropdown_read_user_list = null
  next_page_url = null
  house_message_ids = null

  setHouseMessageIds = (data) =>{
    this.house_message_ids = data
  }

  getMessageHouseList = () => {
    return Axios.get(`api/dashboardHouse`)
      .then(({ data }) => {
        this.dropdown_message_house_list = data;
        this.sethouseId(data[0].id)
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getBroadcastMessageList = (userID, houseId, page = 1) => {
    return Axios.post(`api/broadcastmessages/receivemessage/${userID}?page=${page}`, houseId)
      .then(({ data:{data, message_ids}}) => {
        this.dropdown_broadcast_message_list = data.data;
        this.next_page_url = data.next_page_url;
        this.setHouseMessageIds(message_ids)
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  AddBroadcastMessage = (userId, housesId, data, page = 1) => {
    return Axios.post(`/api/broadcastmessages/sendmessage/${userId}`, data).then(({ data }) => {
      let house_Id = { house_id: housesId };
      this.getBroadcastMessageList(userId, house_Id, page)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  deleteBroadcastMessage = async (userId, messageId, page = 1) => {
    const data = {
      message_id: messageId
    };
    return Axios.post(`/api/broadcastmessages/delete/${userId}`, data).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  ReadByuserList = (messageID) => {
    return Axios.post(`api/broadcastmessages/is_read/${messageID}`)
      .then(({ data }) => {
        this.dropdown_read_user_list = data.data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  sethouseId = (data) => {
    this.houseId = data;
  }

}

decorate(BroadcastMessageStore, {
  getMessageHouseList: action,
  getBroadcastMessageList: action,
  AddBroadcastMessage: action,
  sethouseId: action,
  deleteBroadcastMessage: action,
  ReadByuserList: action,
  setHouseMessageIds: action,
  house_message_ids: observable,
  dropdown_message_house_list: observable,
  dropdown_broadcast_message_list: observable,
  broadcastmessage_data: observable,
  houseId: observable,
  dropdown_read_user_list: observable,
  next_page_url: observable,
})