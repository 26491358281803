import Axios from "axios"
import { decorate, observable, action } from "mobx"
import { vsmNotify } from "../config/messages"
import { logos } from "../pages/Reports/ImageUtils"

export default class AppsAuthStore {
  prospects_user = null
  prospects_token = null
  prospects_open_login = false
  loading = false
  apps_ledger_base_currency = null
  apps_menu = null
  permissions = null
  list_fullscreen = false
  fullscreen_class = ''
  theme_color = null
  theme_button_color = null
  theme_type = null
  c_etr_sell_rate = null
  dashboardValue = true
  d_etr_purchase_discount = null
  menuHasSubmenu = [];
  server_document_url = "";
  notification_list = null;
  last_page_url = null;
  notification_count = null;

  toggleListFullscreen = () => {
    this.list_fullscreen = this.list_fullscreen ? false : true
    this.fullscreen_class = this.list_fullscreen ? 'full_screen_wrapper' : ''
  }
  constructor() {
    this.initiatAppOptions()
  }
  // set base url & add interceptors to axios
   setAppAxiosDefaults = () => {
    // Axios.defaults.baseURL = 'http://api-coreprospects.silicontechnolabs.com';
    // this.server_document_url = 'http://api-coreprospects.silicontechnolabs.com';
    Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    this.server_document_url =  process.env.REACT_APP_BASE_URL;
     
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }


  doAppsLogin = (data) => {
    return Axios.post('api/login', data).then(({ data }) => {
      localStorage.setItem('app_token', data.access_token)
      this.initiatAppOptions()
      this.prospects_open_login = false
    }).catch(({ response: { data } }) => {
      var errors = []
      Object.keys(data.errors).forEach(name => {
        errors.push({ name, errors: data.errors[name] })
      })
      data.errors = errors
      return Promise.reject(data)
    })
  }


  // set base url & add interceptors to axios
  setAppsAxiosInterceptors = () => {
    Axios.interceptors.response.use((data) => {
      return data
    }, (data) => {
      if (data.response) {
        if (data.response.status === 403) {
          vsmNotify.error({ message: data.response?.data?.errors?.general?.[0] })
        }
        else if (data.response.status === 401) {
          this.clearLocalStorage()
          this.prospects_open_login = true
        } else if (data.response.status === 500) {
          vsmNotify.error({
            message: "Something went wrong. For more detail [500]"
          })
        } else if (data.response.status === 422) {
          if (data.response.data.errors) {
            Object.keys(data.response.data.errors).map((err) => 
            vsmNotify.error({
                message: data.response.data.errors[err]
              }))
          }
        }
      } else {
        vsmNotify.error({
          message: "Network Problem."
        })
        data.errors = null
      }
      return Promise.reject(data)
    })
  }

  // set axios default setting for api call
  initiatAppOptions = () => {
    this.loading = true
    this.setAppAxiosDefaults()

    let token = localStorage.getItem('app_token')
    if (token && token !== 'undefined') {

      Axios.interceptors.request.use((config) => {
        let token = localStorage.getItem('app_token');
        if (token && token !== 'undefined') {
          config.headers.Authorization = 'Bearer ' + token;
          config.headers.Accept = 'application/json';
          return config;
        } else {
          this.setOriginatorUser(null, null)
          this.loading = false;
          return config;
        }
      });

      this.dashboardValue = true
      this.setUserOptions(token)
    } else {
      this.setOriginatorUser(null, null)
      this.loading = false
    }
  }

  // make a api call to get current user & accessible menu
  setUserOptions = (token) => {
    Axios.post('api/me').then(({ data }) => {
      this.setOriginatorUser(data.user, token)
      this.permissions = data.permissions
      this.apps_menu = data.menu;
      // ------- To Filter Menu has Submenu keys ----- Used for collapse menu //
      let menuArray = data.menu;
      let filterArr = [];
      Object.keys(menuArray).map((obj) => {
        let currentVal = menuArray[obj];
        if (currentVal.hasOwnProperty('submenu')) {
          filterArr.push(currentVal.title + (filterArr.length + 1))
        }
        return null;
      })
      this.menuHasSubmenu = filterArr;
      this.getNotificationCount()
      // localStorage.setItem('notification_count', data.notification_count)
      // ------- -------------------------------------------------------------//

      this.loading = false
    }).catch(e => {
      this.clearLocalStorage()
      this.initiatAppOptions()
    })
  }

  // set authenticated originator user to mobx store
  setOriginatorUser = (user_detail, token) => {
    this.prospects_user = user_detail
    this.prospects_token = token
  }

  clearLocalStorage = () => {
    localStorage.removeItem('app_token')
    localStorage.removeItem('params')
    localStorage.removeItem('fcmToken')
    localStorage.removeItem("system_form_filter")
    localStorage.removeItem("lastActvity")
  }


  checkPermission = (module_id, permission_type) => {
    if (this.prospects_user && this.prospects_user.id === 1) {
      return true
    } else {
      if (this.permissions && this.permissions[module_id] && this.permissions[module_id].includes(permission_type)) {
        return true
      } else {
        return false
      }
    }
  }

  doAppsForgetPass = (formData) => {
    return Axios.post('/api/forgotpassword', formData).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      Object.keys(data.errors).forEach(name => {
        errors.push({ name, errors: data.errors[name] })
      })
      data.errors = errors
      return Promise.reject(data)
    })
  }

  doLogOut = () => {
    this.loading = true
    return Axios.post('/api/logout').then(({ data }) => {
      this.loading = false
      Axios.defaults.headers = {
        Accept: 'application/json'
      }
      this.setOriginatorUser(null, null)
      this.prospects_open_login = false
      this.clearLocalStorage()
    }).catch(e => {
      this.loading = false
      this.dashboardValue = true
      return Promise.reject()
    })
  }

  doAppsResetPass = (formData) => {
    return Axios.post('/api/user/updatenewpassword', formData).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      Object.keys(data.errors).forEach(name => {
        errors.push({ name, errors: data.errors[name] })
      })
      data.errors = errors
      return Promise.reject(data)
    })
  }


  defaulExcelImage = () => {
    return {
      prependContent: [
        [
          {
            data: {
              type: 'String',
              value: logos.AgGrid,
            },
            mergeAcross: 3,
          },
        ],
      ],
      rowHeight: (params) => (params.rowIndex === 1 ? 500 : 20),
      addImageToCell: (rowIndex, col, value) => {
        if (rowIndex !== 1 || col.getColId() !== 'id') {
          return;
        }
        return {
          image: {
            id: 'logo',
            base64: value,
            imageType: 'png',
            width: 295,
            // height: 140,
            position: {
              colSpan: 5,
              offsetX: 350
            },
          },
        };
      },
    };
  }

  getNotificationCount = () => {
    return Axios.get(`/api/notificationcount`).then(({ data }) => {
      this.notification_count = data.notification_count
      localStorage.setItem("notificationCount", this.notification_count)
      // this.total = data.total
      // this.current_page = data.current_page
      return data
    })
  }

  // setNotificationCount = (value) => {
  //   this.notification_count = value
  // }

  getNotificationList = (pageNo = 1) => {
    return Axios.get(`/api/notification/list?page=${pageNo}`).then(({ data }) => {
      this.notification_list = data.data
      this.last_page_url = data.next_page_url
      // this.total = data.total
      // this.current_page = data.current_page
      return data
    })
  }

  DeleteNotification = (id) => {
    return Axios.post(`/api/notification/delete/${id}`).then(({ data }) => {
      // this.getNotificationList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

}


decorate(AppsAuthStore, {
  theme_color: observable,
  theme_button_color: observable,
  theme_type: observable,
  prospects_user: observable,
  prospects_token: observable,
  prospects_open_login: observable,
  loading: observable,
  permissions: observable,
  list_fullscreen: observable,
  fullscreen_class: observable,
  apps_menu: observable,
  menuHasSubmenu: observable,
  server_document_url: observable,
  notification_list: observable,
  notification_count: observable,
  last_page_url: observable,
  defaulExcelImage: action,
  initiatAppOptions: action,
  setAppAxiosDefaults: action,
  setAppsAxiosInterceptors: action,
  setUserOptions: action,
  checkPermission: action,
  toggleListFullscreen: action,
  doAppsForgetPass: action,
  doLogOut: action,
  doAppsResetPass: action,
  JsonToFormData: action,
  getNotificationList: action,
  DeleteNotification: action,
  getNotificationCount: action,
})
