import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../config/globalsFunc";

export default class MasterEthnicityStore {
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
  agGrid = null
  dropdown_ethinicity_list = null


  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  getList = () => {
    return Axios.post(`/api/ethnicity/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          item.is_active_display =
            item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  onGridChanged = (params) => {
    localStorage.setItem('Ethinicity', JSON.stringify(params.columnApi.getColumnState()))
  }

  EditMasterEthinicity = (data) => {
    return Axios.post(`/api/ethnicity/edit/${data.id}`, { "name": data.name }).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  ActiveStatus = (id, status) => {
    Axios.post(`/api/ethnicity/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteMasterEthinicity = (id) => {
    return Axios.post(`/api/ethnicity/delete/${id}`).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: 'successfully Deleted' })
      }
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  AddMasterEthinicity = (name) => {
    return Axios.post(`/api/ethnicity/add`, name).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getEthinicityList = () => {
    return Axios.get(`api/ethnicity/getethnicitylist`)
      .then(({ data }) => {
        this.dropdown_ethinicity_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

}

decorate(MasterEthnicityStore, {
  AddMasterEthinicity: action,
  EditMasterEthinicity: action,
  DeleteMasterEthinicity: action,
  List_data: observable,
  agGrid: observable,
  dropdown_ethinicity_list: observable,
  ActiveStatus: action,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  onGridChanged: action,
  getEthinicityList: action
})