export const CONSTANT = {
    ExportTypeListing: {
        PDF: 'pdf',
        CSV: 'csv',
        EXCEL: 'excel'
    },
    RoleIdConsant: {
        SUPERADMIN: 1,
        SERVICEMANAGER: 2,
        DEPUTYMANAGER: 3,
        SUPPORTWORKER: 4
    },
    FinanceRecordAmount: {
        MAX: 99999,
        MIN: 0.01
    },
    FinanceType: {
        INCOME: 1,
        EXPENDITURE: 2
    }
}